<template>
  <div v-if="analyticsData">
    <b-row class="d-flex align-items-center">
      <b-col cols="12">
        <AnalyticsDateSelector
          title="SHIPMENTS DASHBOARD"
          :is-loading="isLoading"
          @handleExtract="handleExtract"
          @handleExport="handleExport"
        />
      </b-col>

      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              v-for="(item, index) in shipments"
              :key="index"
              cols="12"
              lg="6"
            >
              <StatisticsCard
                :statistics="item"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card>
          <AnalyticsLineGraph
            v-if="!isFetchingGraph"
            :title="'Shipments'"
            :chart-data="graphData"
            :selected-filter-by="filterBy"
            @filterGraphBy="filterGraphBy"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go back
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  StatisticsCard, AnalyticsLineGraph, AnalyticsDateSelector,
} from '@/components'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'ShipmentsAnalytics',
  components: {
    StatisticsCard,
    AnalyticsLineGraph,
    AnalyticsDateSelector,
  },
  mixins: [analytics],
  data() {
    return {
      shipments: [],
      type: 'shipments',
      hasGraph: true,
    }
  },
  watch: {
    analyticsData(newValue) {
      this.shipments = newValue
    },
  },
}
</script>
